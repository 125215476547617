@import "./sweetalert2/sweetalert2";

.questions {
  img {
    width: 50%;
  }
}

.question-field {
  .ql-container {
    .ql-editor {
      p {
        img {
          width: 50%;
        }
      }
    }
  }
}